// TODO not sure if these two are needed
import React, {Fragment} from '@preact/compat'
import {render, h} from 'preact'

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
import 'chartkick/chart.js'
import "../channels"
// This is used here to import all controllers. Other more specific JS packs define a controlled
// list of packs in the same list format generated by this import.
import {definitions} from 'stimulus:../controllers/app'
import {definitions as stimulusDefinitions} from 'stimulus:../controllers/app/data_table'
import {initLogging} from '../utils/logger'
import {initStimulus} from '../utils/stimulus'
import '../utils/turbo-helper'

window.provenance = window.provenance || {}
initLogging()
Rails.start()
ActiveStorage.start()
initStimulus(definitions.concat(stimulusDefinitions))
