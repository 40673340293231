{
  "da": {
    "feedback": {
      "cta": "Hjalp dette?",
      "positive_feedback": null,
      "report": {
        "cta": null,
        "mail_body": null,
        "mail_subject": null,
        "prompt": null
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Af %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Se profil"
        },
        "share": {
          "button": "%{title}, en produkthistorie fra %{organizationName}",
          "copied_clipboard": "Kopieret til clipboard",
          "facebook": "Del på facebook",
          "intro": "Del på",
          "pinterest": "Del på Pinterest",
          "twitter": "Del på Twitter"
        }
      }
    },
    "js": {
      "checking": "Kontrol",
      "evidenced": "Bevist",
      "footer_cta": "Følg for tips om shopping bevidst!",
      "proof_point": {
        "partly_recyclable_packaging": "Delvis Genanvendelig Emballage",
        "vegan": "Vegansk"
      },
      "theme": {
        "nature": "Natur",
        "waste": "Spild"
      },
      "unverified": "Ubekræftet",
      "verified_label": "Verificeret",
      "view_profile": "Se profil"
    },
    "products": {
      "js": {
        "map": {
          "modal_cta": "Se detaljer"
        }
      }
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": null
        },
        "claims": {
          "how_achieved": null,
          "label": "Påstande",
          "title": "%{claimHeaderName} krav",
          "what": "Hvad de gør"
        },
        "connection": {
          "network": "Netværk"
        },
        "evidence": {
          "no_evidence": "Intet vidnesbyrd er tilføjet endnu.",
          "see_proof": null,
          "self_evidenced": "Mærket har leveret deres egne beviser til støtte for denne påstand.",
          "verified_by": "Kravet %{claimName} er verificeret af denne tredjepart",
          "view": null,
          "view_verifier": "Se, hvem der bekræftende"
        },
        "find_more": "Få mere at vide",
        "location": {
          "where": "Hvem de er"
        },
        "meaning": {
          "what": null
        },
        "network": {
          "provenance": null,
          "verified": null,
          "view_profile": null
        },
        "product": {
          "associated": null
        },
        "proof": {
          "see_record": null,
          "view_claim": null,
          "view_permanent": null
        },
        "report": {
          "unverified_cta": "Vil du se stærkere vidnesbyrd?",
          "verified_cta": "Er der noget, som ikke er tydeligt om disse oplysninger?"
        },
        "title": "Vidnesbyrd",
        "valid_until": "Gyldig indtil:",
        "verified": {
          "3rd_party": null,
          "how": null
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "de": {
    "feedback": {
      "cta": "War dies hilfreich?",
      "options": {
        "no_evidence": "Nein, ich brauche mehr Beweise für diese Behauptung",
        "no_help": "Nein, aber ich möchte mehr Hilfe beim nachhaltigen Einkaufen",
        "no_info": "Nein, ich brauche klarere Informationen",
        "no_initiative": "Nein, aber tolle Initiative der Marke",
        "no_none": "Nichts des oben Genannten",
        "yes_help": "Ja, ich möchte mehr Hilfe beim nachhaltigen Einkaufen",
        "yes_love": "Ja, ich liebe die Mission der Marke",
        "yes_none": "Nichts des oben Genannten",
        "yes_trust": "Ja, ich vertraue dieser Behauptung",
        "yes_understand": "Ja, ich verstehe diese Behauptung"
      },
      "positive_feedback": "Vielen Dank für Ihr Feedback.",
      "report": {
        "cta": "Haben Sie Vorschläge, wie wir uns noch verbessern können",
        "mail_body": null,
        "mail_subject": null,
        "prompt": "Schicken Sie uns eine E-mail."
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Von %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Profil anzeigen"
        },
        "share": {
          "button": "%{title}, eine Produktgeschichte von %{organizationName}",
          "copied_clipboard": "In die Zwischenablage kopiert",
          "facebook": "Auf Facebook teilen",
          "intro": "Teilen auf",
          "pinterest": "Auf Pinterest teilen",
          "twitter": "Auf Twitter teilen"
        },
        "value": {
          "description": "Provenance informiert Sie, ob sich ein Produkt positiv auf den Menschen und Planeten auswirkt. Es ist eine Plattform von Drittanbietern, mit der Marken Informationen auf vertrauenswürdige Weise öffnen können. Sie können dann Produkte auswählen, die den Wandel in der Welt vorantreiben.",
          "link": "Finden Sie heraus, wie es funktioniert"
        },
        "value_tip": {
          "description": "Erfahren Sie die Geschichte hinter der Marke, der Lieferkette und ihren Produkten. Diese Marke ist eine Partnerschaft mit PROVENANCE® eingegangen. Sie sind Dritte, die dazu beitragen, Nachhaltigkeitsinformationen auf eine Weise zu öffnen, der Sie vertrauen können.",
          "title": "Was ist das?"
        }
      }
    },
    "js": {
      "checking": "Überprüfung",
      "evidenced": "Bewiesen",
      "footer_cta": "Hier finden Sie Tipps für bewusstes Einkaufen!",
      "proof_point": {
        "partly_recyclable_packaging": "Teilweise Recycelbare Verpackung",
        "vegan": "Vegan"
      },
      "theme": {
        "nature": "Natur",
        "waste": "Abfall"
      },
      "unverified": "Nicht überprüft",
      "verified_label": "Verifiziert",
      "view_profile": "Profil anzeigen"
    },
    "products": {
      "js": {
        "assets": {
          "help": "Oder erkunden Sie die zuletzt für dieses Produkt registrierten Assets.",
          "issued_by": "Ausgestellt von",
          "item_id": "Artikel Identifikationsnummer",
          "last_updated": "Zuletzt aktualisiert",
          "owned_by": "Eigentum von",
          "received": "Erhalten"
        },
        "map": {
          "modal_cta": "siehe Einzelheiten"
        }
      }
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": "Organisation anzeigen"
        },
        "claims": {
          "how_achieved": "Wie wir das erreicht haben",
          "label": "Ansprüche",
          "title": "Anspruch %{claimHeaderName}",
          "what": "Was sie machen"
        },
        "connection": {
          "network": "Netzwerk"
        },
        "evidence": {
          "no_evidence": "Es wurden noch keine Beweise hinzugefügt.",
          "see_proof": "Siehe die Beweise",
          "self_evidenced": "Die Marke hat ihre eigenen Beweise vorgelegt, um diese Behauptung zu belegen.",
          "verified_by": "Der Anspruch von %{claimName} wurde von diesem Dritten überprüft",
          "view": "Beweise anzeigen",
          "view_verifier": "Verifizierer anzeigen"
        },
        "find_more": "Finde mehr heraus",
        "location": {
          "where": "Wo sie sind"
        },
        "meaning": {
          "what": "Was bedeutet %{title}?"
        },
        "network": {
          "provenance": "Dies wurde durch die Provenienz überprüft.",
          "verified": "%{name} hat ihre Verbindung mit %{organizationName} digital bestätigt.",
          "view_profile": "Profil anzeigen"
        },
        "product": {
          "associated": "Zugehöriges Produkt"
        },
        "proof": {
          "see_record": "Sehen Sie den Blockchain-Datensatz",
          "view_claim": "Anspruch anzeigen",
          "view_permanent": "Eine permanente Aufzeichnung dieser Aussage anzeigen: %{recordType}"
        },
        "title": "Beweispunkte",
        "valid_until": "Gültig bis:",
        "verified": {
          "3rd_party": "Ein unabhängiger Dritter (siehe oben) hat die Richtigkeit überprüft",
          "how": "Wie wurde dies verifiziert?"
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "en": {
    "feedback": {
      "cta": "Does this information help you shop more sustainably?",
      "options": {
        "directory": {
          "no_evidence": "No, I need more evidence for this information",
          "no_help": "No, but I want more help to shop sustainably",
          "no_info": "No, I need clearer information",
          "no_initiative": "No, but great initiative from the brand",
          "no_none": "None of the above",
          "yes_help": "Yes, I want more help to shop sustainably",
          "yes_love": "Yes, I love the brand's mission",
          "yes_none": "None of the above",
          "yes_trust": "Yes, I trust this information",
          "yes_understand": "Yes, I understand this information"
        },
        "no_evidence": "No, I need more evidence for this claim",
        "no_help": "No, but I want more help to shop sustainably",
        "no_info": "No, I need clearer information",
        "no_initiative": "No, but great initiative from the brand",
        "no_none": "None of the above",
        "yes_help": "Yes, I want more help to shop sustainably",
        "yes_love": "Yes, I love the brand's mission",
        "yes_none": "None of the above",
        "yes_trust": "Yes, I trust this claim",
        "yes_understand": "Yes, I understand this claim"
      },
      "positive_feedback": "Thanks for your feedback.",
      "report": {
        "cta": "Let Provenance know how to improve.",
        "mail_body": "Dear Provenance, I didn't find this helpful because...",
        "mail_subject": "Feedback for Provenance",
        "prompt": "Send us an email"
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "By %{organizationName}"
        },
        "credits": {
          "exit_button_label": "View Profile"
        },
        "share": {
          "button": "%{title}, a product story from %{organizationName}",
          "copied_clipboard": "Copied to clipboard",
          "facebook": "Share on Facebook",
          "intro": "Share on",
          "pinterest": "Share on Pinterest",
          "twitter": "Share on Twitter"
        },
        "value": {
          "description": "Provenance helps you know if a product has a positive impact on people and planet. They are a third party platform that helps brands open up information in a way you can trust. You can then choose the products that drive change in the world.",
          "link": "Find out how it works"
        },
        "value_tip": {
          "description": "Find out the story behind the brand, supply chain and their products. This brand has partnered with PROVENANCE®. They are a third party that help open up sustainability information in a way you can trust.",
          "title": "What is this?"
        }
      }
    },
    "js": {
      "checking": "Checking",
      "evidenced": "Evidenced",
      "footer_cta": "Follow for tips on shopping consciously!",
      "proof_point": {
        "partly_recyclable_packaging": "Partly Recyclable Packaging",
        "vegan": "Vegan"
      },
      "theme": {
        "nature": "Nature",
        "waste": "Waste"
      },
      "unverified": "Unverified",
      "verified_label": "Verified",
      "view_profile": "View profile"
    },
    "products": {
      "js": {
        "map": {
          "modal_cta": "See details"
        }
      }
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": "View Scheme"
        },
        "claims": {
          "how_achieved": "How we’ve achieved this",
          "label": "Claims",
          "title": "%{claimHeaderName} claim",
          "what": "What they do"
        },
        "connection": {
          "network": "Network"
        },
        "evidence": {
          "no_evidence": "No evidence has been added yet.",
          "provided_by": "Provided by",
          "see_evidence": "See Evidence",
          "see_proof": "See the evidence",
          "see_verification": "See Verification",
          "self_evidenced": "The brand has provided their own evidence to support this claim.",
          "verified_by": "The %{claimName} claim has been verified by this third party",
          "view": "View evidence",
          "view_verifier": "View verifier"
        },
        "find_more": "Find out more",
        "location": {
          "where": "Where they are"
        },
        "meaning": {
          "what": "What does %{title} mean?"
        },
        "network": {
          "provenance": "This has been verified by Provenance.",
          "verified": "%{name} has digitally confirmed their connection with %{organizationName}.",
          "view_profile": "View profile"
        },
        "product": {
          "associated": "Associated product"
        },
        "proof": {
          "see_record": "See the blockchain record",
          "view_claim": "View Claim",
          "view_permanent": "View a permanent record of this %{recordType}"
        },
        "recycling": {
          "attribution": "Data provided by Valpak® & WRAP®",
          "cta": "Enter your UK postcode to see how you can recycle this",
          "error": {
            "description": "Please check your postcode and try again.",
            "title": "There was an error"
          },
          "header": "Recycling (Beta)",
          "results": {
            "facilities": {
              "description": "Recycle %{packaging_list} here:",
              "title": "Recycle these parts nearby"
            },
            "scheme": {
              "description": "Recycle the %{packaging_list} through your home recycling collection.",
              "title": "Recycle these parts at home"
            },
            "unrecyclable": {
              "description": "You can’t currently recycle the %{packaging_list} in your area.",
              "title": "You cannot recycle these parts"
            }
          },
          "search": {
            "action": "Recycling instructions",
            "hint": "e.g. N1 6LA"
          }
        },
        "themes": {
          "explore": "Explore all 5 Impact Themes on the Provenance Framework"
        },
        "title": "Proof Points",
        "valid_until": "Valid until:",
        "verified": {
          "3rd_party": "An independent third party (shown above) has checked the accuracy of this",
          "how": "How was this verified?"
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "es": {
    "feedback": {
      "cta": "¿Esta información le ayuda a comprar de forma más sostenible?",
      "options": {
        "directory": {
          "no_evidence": "No, necesito más evidencia para esta información.",
          "no_help": "No, pero quiero más ayuda para comprar de forma sostenible",
          "no_info": "No, necesito información más clara.",
          "no_initiative": "No, pero gran iniciativa de la marca.",
          "no_none": "Ninguna de las anteriores",
          "yes_help": "Sí, quiero más ayuda para comprar de forma sostenible",
          "yes_love": "Sí, me encanta la misión de la marca.",
          "yes_none": "Ninguna de las anteriores",
          "yes_trust": "Sí, confío en esta información.",
          "yes_understand": "Sí, entiendo esta información."
        },
        "no_evidence": "No, necesito más pruebas para esta afirmación.",
        "no_help": "No, pero quiero más ayuda para comprar de forma sostenible",
        "no_info": "No, necesito información más clara.",
        "no_initiative": "No, pero gran iniciativa de la marca.",
        "no_none": "Ninguna de las anteriores",
        "yes_help": "Sí, quiero más ayuda para comprar de forma sostenible",
        "yes_love": "Sí, me encanta la misión de la marca.",
        "yes_none": "Ninguna de las anteriores",
        "yes_trust": "Sí, confío en esta afirmación.",
        "yes_understand": "Sí, entiendo esta afirmación."
      },
      "positive_feedback": "Gracias por tus comentarios.",
      "report": {
        "cta": "Díganos como Provenance puede mejorar",
        "mail_body": "Querido Provenance: No encontré esto útil porque...",
        "mail_subject": "Opiniones para Provenance",
        "prompt": "envianos un email"
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Por %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Ver perfil"
        },
        "share": {
          "button": "%{title}, una historia de producto de %{organizationName}",
          "copied_clipboard": "Copiado al portapapeles",
          "facebook": "Compartir en Facebook",
          "intro": "Compartir en",
          "pinterest": "Compartir en Pinterest",
          "twitter": "Compartir en Twitter"
        },
        "value": {
          "description": "La procedencia le ayuda a saber si un producto tiene un impacto positivo en las personas y el planeta. Son una plataforma de terceros que ayuda a las marcas a abrir información de una manera confiable. Luego podrá elegir los productos que impulsan el cambio en el mundo.",
          "link": "Descubra cómo funciona"
        },
        "value_tip": {
          "description": "Descubra la historia detrás de la marca, la cadena de suministro y sus productos. Esta marca se ha asociado con PROVENANCE®. Son un tercero que ayuda a abrir información sobre sostenibilidad de una manera en la que puede confiar.",
          "title": "¿Qué es esto?"
        }
      }
    },
    "js": {
      "checking": "Comprobando",
      "evidenced": "Evidenciado",
      "footer_cta": "¡Síguenos para conocer consejos sobre cómo comprar conscientemente!",
      "proof_point": {
        "partly_recyclable_packaging": "Embalaje Parcialmente Reciclable",
        "vegan": "Vegano"
      },
      "theme": {
        "nature": "Naturaleza",
        "waste": "La Pérdida"
      },
      "verified_label": "Verificado",
      "view_profile": "Ver perfil"
    },
    "products": {
      "js": {
        "map": {
          "modal_cta": "Ver detalles"
        }
      }
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": "Ver esquema"
        },
        "claims": {
          "how_achieved": "Cómo hemos logrado esto",
          "label": "Reclamos",
          "title": "Afirmación %{claimHeaderName}",
          "what": "Lo que hacen"
        },
        "connection": {
          "network": "Red"
        },
        "evidence": {
          "no_evidence": "Aún no se ha añadido evidencia.",
          "provided_by": "Proporcionado por",
          "see_evidence": "Ver evidencia",
          "see_proof": "ver la evidencia",
          "see_verification": "Ver Verificación",
          "self_evidenced": "La marca ha proporcionado su propia evidencia para respaldar esta afirmación.",
          "verified_by": "La afirmación %{claimName} ha sido verificada por este tercero.",
          "view": "Ver evidencia",
          "view_verifier": "Ver verificador"
        },
        "find_more": "Ver más",
        "location": {
          "where": "Dónde están"
        },
        "meaning": {
          "what": "¿Qué significa %{title}?"
        },
        "network": {
          "provenance": "Esto ha sido verificado por Provenance.",
          "verified": "%{name} ha confirmado digitalmente su conexión con %{organizationName}.",
          "view_profile": "Ver perfil"
        },
        "product": {
          "associated": "Producto asociado"
        },
        "proof": {
          "see_record": "Ver el registro de blockchain",
          "view_claim": "Ver afirmación",
          "view_permanent": "Ver un registro permanente de este %{recordType}"
        },
        "recycling": {
          "attribution": "Datos proporcionados por Valpak® & WRAP®",
          "cta": "Ingrese su código postal del Reino Unido para ver cómo puede reciclarlo",
          "error": {
            "description": "Por favor verifique su código postal e inténtelo nuevamente.",
            "title": "Hubo un error"
          },
          "header": "Reciclaje (Beta)",
          "results": {
            "facilities": {
              "description": "Recicle %{packaging_list} aquí:",
              "title": "Recicla estas piezas cerca"
            },
            "scheme": {
              "description": "Recicle el %{packaging_list} a través de la recolección de reciclaje de su hogar.",
              "title": "Recicla estas piezas en casa."
            },
            "unrecyclable": {
              "description": "Actualmente no puedes reciclar el %{packaging_list} en tu área.",
              "title": "No puedes reciclar estas piezas."
            }
          },
          "search": {
            "action": "Instrucciones de reciclaje",
            "hint": "p.ej. N1 6LA"
          }
        },
        "themes": {
          "explore": "Explore las 5 categorías de impacto en el marco de Provenance"
        },
        "title": "Proof Points",
        "valid_until": "Válido hasta:",
        "verified": {
          "3rd_party": "La exactitud de esto fue verificado por un tercero independiente (mostrado arriba)",
          "how": "¿Cómo se comprobó esto?"
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "fi": {
    "feedback": {
      "cta": null,
      "positive_feedback": null,
      "report": {
        "cta": null,
        "mail_body": null,
        "mail_subject": null,
        "prompt": null
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Kirjoittanut %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Näytä profiili"
        },
        "share": {
          "button": "%{title}, tuotejuttu %{organizationName}: ltä",
          "copied_clipboard": "Kopioitiin leikepöydälle",
          "facebook": "Jaa Facebookissa",
          "intro": "Jaa",
          "pinterest": "Jaa Pinterestissä",
          "twitter": "Jaa Twitterissä"
        }
      }
    },
    "js": {
      "checking": "Tarkistamassa",
      "evidenced": "Todistettu",
      "footer_cta": "Seuraa vinkkejä tietoiseen ostamiseen!",
      "proof_point": {
        "partly_recyclable_packaging": "Osittain Kierrätettävä Pakkaus",
        "vegan": "Vegaaninen"
      },
      "theme": {
        "nature": "Luonto",
        "waste": "Jäte"
      },
      "unverified": "Vahvistamaton",
      "verified_label": "Vahvistettu",
      "view_profile": "Näytä profiili"
    },
    "products": {
      "js": null
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": null
        },
        "claims": {
          "how_achieved": null,
          "label": "väittää",
          "title": "%{claimHeaderName}-vaatimus",
          "what": "Mitä he tekevät"
        },
        "connection": {
          "network": "verkko"
        },
        "evidence": {
          "no_evidence": "Todisteita ei ole vielä lisätty.",
          "see_proof": null,
          "self_evidenced": "Brändi on esittänyt omat todisteensa tämän väitteen tueksi.",
          "verified_by": "Tämä kolmas osapuoli on vahvistanut %{claimName}-vaatimuksen",
          "view": null,
          "view_verifier": "Näytä varmentaja"
        },
        "find_more": "Lue lisää",
        "location": {
          "where": "Missä he ovat"
        },
        "meaning": {
          "what": null
        },
        "network": {
          "provenance": null,
          "verified": null,
          "view_profile": null
        },
        "product": {
          "associated": null
        },
        "proof": {
          "see_record": null,
          "view_claim": null,
          "view_permanent": null
        },
        "report": {
          "unverified_cta": "Haluatko nähdä vahvempia todisteita?",
          "verified_cta": "Onko mitään selvää näistä tiedoista?"
        },
        "title": "Todistuspisteet",
        "valid_until": "Voimassa:",
        "verified": {
          "3rd_party": null,
          "how": null
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "fr": {
    "feedback": {
      "cta": "Ces informations vous aident-elles à faire des achats plus durables ?",
      "options": {
        "directory": {
          "no_evidence": "Non, j'ai besoin de plus de preuves pour cette information",
          "no_help": "Non, mais je veux plus d'aide pour faire des achats durables",
          "no_info": "Non, j'ai besoin d'informations plus claires",
          "no_initiative": "Non mais belle initiative de la marque",
          "no_none": "Aucune des réponses ci-dessus",
          "yes_help": "Oui, je veux plus d'aide pour faire des achats durables",
          "yes_love": "Oui, j'aime la mission de la marque",
          "yes_none": "Aucune des réponses ci-dessus",
          "yes_trust": "Oui, je fais confiance à ces informations",
          "yes_understand": "Oui, je comprends cette information"
        },
        "no_evidence": "Non, j'ai besoin de plus de preuves pour cette affirmation",
        "no_help": "Non, mais je veux plus d'aide pour faire des achats durables",
        "no_info": "Non, j'ai besoin d'informations plus claires",
        "no_initiative": "Non mais belle initiative de la marque",
        "no_none": "Aucune des réponses ci-dessus",
        "yes_help": "Oui, je veux plus d'aide pour faire des achats durables",
        "yes_love": "Oui, j'aime la mission de la marque",
        "yes_none": "Aucune des réponses ci-dessus",
        "yes_trust": "Oui, je fais confiance à cette affirmation",
        "yes_understand": "Oui, je comprends cette affirmation"
      },
      "positive_feedback": "Merci pour vos commentaires.",
      "report": {
        "cta": "Faites savoir à Provenance comment s’améliorer.",
        "mail_body": "Chère Provenance, je n'ai pas trouvé cela utile parce que...",
        "mail_subject": "Commentaires sur la provenance",
        "prompt": "Envoyez-nous un e-mail"
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Par %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Voir le profil"
        },
        "share": {
          "button": "%{title}, une histoire de produit de %{organizationName}",
          "copied_clipboard": "Copié dans le presse-papier",
          "facebook": "Partager sur Facebook",
          "intro": "Partager sur",
          "pinterest": "Partager sur Pinterest",
          "true": "Partager sur",
          "twitter": "Partager sur Twitter"
        },
        "value": {
          "description": "La provenance vous aide à savoir si un produit a un impact positif sur les personnes et la planète. Il s'agit d'une plate-forme tierce qui aide les marques à ouvrir les informations d'une manière fiable. Vous pouvez alors choisir les produits qui stimulent le changement dans le monde.",
          "link": "Découvrez comment cela fonctionne"
        },
        "value_tip": {
          "description": "Découvrez l'histoire de la marque, de la chaîne d'approvisionnement et de leurs produits. Cette marque s'est associée à PROVENANCE®. Il s'agit d'un tiers qui aide à ouvrir les informations sur la durabilité d'une manière fiable.",
          "title": "Qu'est-ce que c'est?"
        }
      }
    },
    "js": {
      "checking": "Vérifier",
      "evidenced": "Attesté",
      "footer_cta": "Suivez-nous pour obtenir des conseils pour magasiner consciemment!",
      "proof_point": {
        "partly_recyclable_packaging": "Emballage partiellement recyclable",
        "vegan": "Végétalien"
      },
      "theme": {
        "nature": "Nature",
        "waste": "Déchets"
      },
      "unverified": "Non vérifié",
      "verified_label": "Vérifié",
      "view_profile": "Voir le profil"
    },
    "products": {
      "js": {
        "map": {
          "modal_cta": "Voir les détails"
        }
      }
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": "Voir l'organisation"
        },
        "claims": {
          "how_achieved": "Comment nous y sommes parvenus",
          "label": "Allégations",
          "title": "revendication %{claimHeaderName}",
          "what": "Ce qu'ils font"
        },
        "connection": {
          "network": "Réseau"
        },
        "evidence": {
          "no_evidence": "Aucune preuve n'a encore été ajoutée.",
          "provided_by": "Fourni par",
          "see_evidence": "Voir les preuves",
          "see_proof": "Voir la preuve",
          "see_verification": "Voir Vérification",
          "self_evidenced": "La marque a fourni ses propres preuves pour étayer cette affirmation.",
          "verified_by": "La réclamation %{claimName} a été vérifiée par ce tiers",
          "view": "Afficher les preuves",
          "view_verifier": "Afficher le vérificateur"
        },
        "find_more": "En savoir plus",
        "location": {
          "where": "Où ils sont"
        },
        "meaning": {
          "what": "Que signifie %{title}?"
        },
        "network": {
          "provenance": "Cela a été vérifié par Provenance.",
          "verified": "%{name} a confirmé numériquement sa connexion avec %{organizationName}.",
          "view_profile": "Voir le profil"
        },
        "product": {
          "associated": "Produit associé"
        },
        "proof": {
          "see_record": "Voir l'enregistrement de la blockchain",
          "view_claim": "Afficher la réclamation",
          "view_permanent": "Afficher un enregistrement permanent de ce %{recordType}"
        },
        "recycling": {
          "attribution": "Données fournies par Valpak® & WRAP®",
          "cta": "Entrez votre code postal britannique pour voir comment vous pouvez le recycler",
          "error": {
            "description": "Veuillez vérifier votre code postal et réessayer.",
            "title": "Il y avait une erreur"
          },
          "header": "Recyclage (bêta)",
          "results": {
            "facilities": {
              "description": "Recyclez %{packaging_list} ici :",
              "title": "Recyclez ces pièces à proximité"
            },
            "scheme": {
              "description": "Recyclez le %{packaging_list} via votre collecte de recyclage domestique.",
              "title": "Recyclez ces pièces à la maison"
            },
            "unrecyclable": {
              "description": "Vous ne pouvez actuellement pas recycler le %{packaging_list} dans votre région.",
              "title": "Vous ne pouvez pas recycler ces pièces"
            }
          },
          "search": {
            "action": "Instructions de recyclage",
            "hint": "par exemple. N1 6LA"
          }
        },
        "report": {
          "unverified_cta": "Vous voulez voir des preuves plus solides?",
          "verified_cta": "Rien de clair sur ces informations?"
        },
        "themes": {
          "explore": "Explorez les 5 thèmes d'impact sur le cadre Provenance"
        },
        "title": "Points de preuve",
        "valid_until": "Valable jusque:",
        "verified": {
          "3rd_party": "Un tiers indépendant (illustré ci-dessus) a vérifié l'exactitude de ce",
          "how": "Comment cela a-t-il été vérifié?"
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "nl": {
    "feedback": {
      "cta": "Was dit nuttig?",
      "positive_feedback": "Bedankt voor je feedback.",
      "report": {
        "cta": "Laat Provenance je verbeterpunten horen.",
        "mail_body": "Beste Provenance, ik vond dit niet nuttig, want...",
        "mail_subject": "Feedback voor Provenance",
        "prompt": "Stuur ons een e-mail"
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Door %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Bekijk profiel"
        },
        "share": {
          "button": "\"%{title}, een productverhaal van %{organizationName}\"",
          "copied_clipboard": "Gekopieerd naar klembord",
          "facebook": "Deel op Facebook",
          "intro": "Deel op",
          "pinterest": "Deel op Pinterest",
          "twitter": "Deel op Twitter"
        }
      }
    },
    "js": {
      "checking": "Controleren",
      "evidenced": "Bewezen",
      "footer_cta": "Volg voor tips om bewust te shoppen!",
      "proof_point": {
        "partly_recyclable_packaging": "Gedeeltelijk Recycleerbare Verpakking",
        "vegan": "Veganistisch"
      },
      "theme": {
        "nature": "Natuur",
        "waste": "Het afval"
      },
      "unverified": "Niet geverifieerd",
      "verified_label": "Geverifieerd",
      "view_profile": "Bekijk profiel"
    },
    "products": {
      "js": null
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": "Bekijk bedrijf"
        },
        "claims": {
          "how_achieved": "Hoe we dit hebben bereikt",
          "label": "Claims",
          "title": "\"%{claimHeaderName} claim\"",
          "what": "Wat ze doen"
        },
        "connection": {
          "network": "Netwerk"
        },
        "evidence": {
          "no_evidence": "Er is nog geen bewijs toegevoegd.",
          "see_proof": "Bekijk het bewijs",
          "self_evidenced": "Het merk heeft eigen bewijs geleverd om deze bewering te ondersteunen.",
          "verified_by": "De %{claimName} claim is geverifieerd door deze derde partij",
          "view": "Bekijk bewijs",
          "view_verifier": "Bekijk verificateur"
        },
        "find_more": "Lees meer",
        "location": {
          "where": "Waar ze zijn"
        },
        "meaning": {
          "what": "Wat betekent %{title}?"
        },
        "network": {
          "provenance": "Dit is geverifieerd door Provenance.",
          "verified": "\"%{name} heeft hun band met %{organizationName} digitaal bevestigd.\"",
          "view_profile": "Bekijk profiel"
        },
        "product": {
          "associated": "Verbonden product"
        },
        "proof": {
          "see_record": "Bekijk het blockchain record",
          "view_claim": "Bekijk claim",
          "view_permanent": "Bekijk een permanente registratie van deze %{recordType}"
        },
        "title": "Bewijspunten",
        "valid_until": "Geldig tot:",
        "verified": {
          "3rd_party": "Een onafhankelijke derde partij (hierboven weergeven) heeft de juistheid hiervan gecontroleerd",
          "how": "Hoe is dit geverifieerd?"
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "no": {
    "feedback": {
      "cta": null,
      "positive_feedback": null,
      "report": {
        "cta": null,
        "mail_body": null,
        "mail_subject": null,
        "prompt": null
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Av %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Vis profil"
        },
        "share": {
          "button": "%{title}, en produktfortelling fra %{organizationName}",
          "copied_clipboard": "Kopiert til utklippstavlen",
          "facebook": "Del på Facebook",
          "intro": "Dele på",
          "pinterest": "Del på Pinterest",
          "twitter": "Del på Twitter"
        }
      }
    },
    "js": {
      "checking": "Kontroll",
      "evidenced": "Bevist",
      "footer_cta": "Følg for tips om å handle bevisst!",
      "proof_point": {
        "partly_recyclable_packaging": "Delvis Resirkulerbar Emballasje",
        "vegan": "Vegansk"
      },
      "theme": {
        "nature": "Naturen",
        "waste": "Avfallet"
      },
      "unverified": "Ubekreftet",
      "verified_label": "Bekreftet",
      "view_profile": "Vis profil"
    },
    "products": {
      "js": null
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": null
        },
        "claims": {
          "how_achieved": null,
          "label": "Påstander",
          "title": "%{claimHeaderName} krav",
          "what": "Hva de gjør"
        },
        "connection": {
          "network": "Nettverk"
        },
        "evidence": {
          "no_evidence": "Ingen bevis er lagt til ennå.",
          "see_proof": null,
          "self_evidenced": "Merket har gitt sine egne bevis for å støtte denne påstanden.",
          "verified_by": "Kravet om %{claimName} er bekreftet av denne tredjeparten",
          "view": null,
          "view_verifier": "Vis verifiser"
        },
        "find_more": "Finne ut mer",
        "location": {
          "where": "Hvor de er"
        },
        "meaning": {
          "what": null
        },
        "network": {
          "provenance": null,
          "verified": null,
          "view_profile": null
        },
        "product": {
          "associated": null
        },
        "proof": {
          "see_record": null,
          "view_claim": null,
          "view_permanent": null
        },
        "report": {
          "unverified_cta": "Vil du se sterkere bevis?",
          "verified_cta": "Noe ikke klart om denne informasjonen?"
        },
        "title": "Bevispoeng",
        "valid_until": "Gyldig til:",
        "verified": {
          "3rd_party": null,
          "how": null
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "pl": {
    "feedback": {
      "cta": "Czy to było pomocne?",
      "options": {
        "directory": {
          "no_evidence": "Nie, potrzebuję więcej dowodów na tę informację",
          "no_help": "Nie, ale chcę uzyskać dodatkową pomoc w robieniu zrównoważonych zakupów",
          "no_info": "Nie, potrzebuję jaśniejszych informacji",
          "no_initiative": "Nie, ale świetna inicjatywa marki",
          "no_none": "Żadne z powyższych",
          "yes_help": "Tak, chcę większej pomocy w robieniu zrównoważonych zakupów",
          "yes_love": "Tak, uwielbiam misję marki",
          "yes_none": "Żadne z powyższych",
          "yes_trust": "Tak, ufam tej informacji",
          "yes_understand": "Tak, rozumiem tę informację"
        },
        "no_evidence": "Nie, potrzebuję więcej dowodów na poparcie tego twierdzenia",
        "no_help": "Nie, ale chcę uzyskać dodatkową pomoc w robieniu zrównoważonych zakupów",
        "no_info": "Nie, potrzebuję jaśniejszych informacji",
        "no_initiative": "Nie, ale świetna inicjatywa marki",
        "no_none": "Żadne z powyższych",
        "yes_help": "Tak, chcę większej pomocy w robieniu zrównoważonych zakupów",
        "yes_love": "Tak, uwielbiam misję marki",
        "yes_none": "Żadne z powyższych",
        "yes_trust": "Tak, ufam temu twierdzeniu",
        "yes_understand": "Tak, rozumiem to twierdzenie"
      },
      "positive_feedback": "Dziękujemy za opinię",
      "report": {
        "cta": "Podpowiedz Provenance, jak możemy się poprawić",
        "mail_body": "Drogi Provenance, nie było to pomocne, ponieważ...",
        "mail_subject": "Opinia dla Provenance",
        "prompt": "Wyślij nam wiadomość e-mail"
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Przez %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Zobacz profil"
        },
        "share": {
          "button": " \"%{title}, historia produktu z %{organizationName}\"",
          "copied_clipboard": "Skopiowano do schowka",
          "facebook": "Udostępnij na Facebooku",
          "intro": "Udostępnij na Facebooku",
          "pinterest": "Udostępnij na Pinterest",
          "twitter": "Udostępnij na Twitterze"
        },
        "value": {
          "description": "Provenance pomoże Ci dowiedzieć się, czy konkretny produkt ma pozytywny wpływ na ludzi i planetę. Provenance to platforma strony trzeciej, która pozwala markom ujawniać informacje w sposób, któremu możesz zaufać. Ty dzięki temu możesz wybierać produkty, które napędzają zmiany na świecie.",
          "link": "Zobacz, jak to działa."
        },
        "value_tip": {
          "description": "Odkryj historię kryjącą się za marką, łańcuchem dostaw i produktami. Ta marka jest partnerem PROVENANCE®. Provenance jest stroną trzecią, która pomaga w udostępnianiu informacji o zrównoważonym rozwoju w sposób, któremu możesz zaufać.",
          "title": "Co to jest?"
        }
      }
    },
    "js": {
      "evidenced": "Udowodnione",
      "footer_cta": "Śledź wskazówki dotyczące świadomych zakupów!",
      "proof_point": {
        "partly_recyclable_packaging": "Opakowanie częściowo nadające się do recyklingu",
        "vegan": "Wegańskie"
      },
      "theme": {
        "nature": "Natura",
        "waste": "Odpady"
      },
      "verified_label": "Zweryfikowane",
      "view_profile": "Zobacz profil"
    },
    "products": {
      "js": {
        "map": {
          "modal_cta": "Zobacz szczegóły"
        }
      }
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": "Zobacz schemat"
        },
        "claims": {
          "how_achieved": "Jak do tego doszliśmy",
          "label": "Roszczenia",
          "title": " %{claimHeaderName} roszczenie",
          "what": "Co oni robią"
        },
        "connection": {
          "network": "Sieć"
        },
        "evidence": {
          "no_evidence": "Nie dodano żadnych dowodów",
          "see_proof": "Zobacz dowody",
          "self_evidenced": "Marka przedstawiła własne dowody na poparcie tego twierdzenia.",
          "verified_by": " %{claimName} roszczenie zostało zweryfikowane przez tę stronę trzecią",
          "view": "Zobacz dowody",
          "view_verifier": "Zobacz weryfikator"
        },
        "find_more": "Dowiedz się więcej",
        "location": {
          "where": "Gdzie oni są"
        },
        "meaning": {
          "what": "Co %{title} oznacza?"
        },
        "network": {
          "provenance": "To zostało zweryfikowane przez Provenance",
          "verified": "\"%{name} cyfrowo potwierdził związek z %{organizationName}.\"",
          "view_profile": "Zobacz profil"
        },
        "product": {
          "associated": "Połączony produkt"
        },
        "proof": {
          "see_record": "Zobacz rekordy łańcucha dostaw",
          "view_claim": "Zobacz roszczenie",
          "view_permanent": "Zobacz stały rejestr z tego %{recordType}"
        },
        "recycling": {
          "attribution": {
            "valpak": "Dane dostarczone przez Valpak®"
          },
          "cta": "Wpisz swój kod pocztowy i sprawdź, jak możesz poddać ją recyklingowi",
          "error": "Wystąpił błąd. Prosimy o sprawdzenie swojego kodu pocztowego.",
          "header": "Recykling",
          "results": {
            "facilities": {
              "description": "Zrecyklinguj %{packaging_list} tutaj:",
              "title": "Przetwórz te części w pobliżu"
            },
            "scheme": {
              "description": "Zrecyklinguj %{packaging_list} poprzez domową zbiórkę recyklingu.",
              "title": "Przetwórz te części w domu"
            },
            "unrecyclable": {
              "description": "Obecnie nie możesz zrecyklingować %{packaging_list} w swojej okolicy.",
              "title": "Te części nie nadają się do recyklingu"
            }
          },
          "search": {
            "action": "Szukaj",
            "action_long": "Instrukcje recyklingu",
            "hint": "np. N1 6LA"
          }
        },
        "title": "Punkty Dowodowe",
        "valid_until": "Ważne do:",
        "verified": {
          "3rd_party": "Niezależna strona trzecia (pokazana wyżej) sprawdziła dokładność tego",
          "how": "Jak to zostało zweryfikowane?"
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  },
  "sv": {
    "feedback": {
      "cta": null,
      "positive_feedback": null,
      "report": {
        "cta": null,
        "mail_body": null,
        "mail_subject": null,
        "prompt": null
      }
    },
    "stories": {
      "js": {
        "cover": {
          "by": "Av %{organizationName}"
        },
        "credits": {
          "exit_button_label": "Visa profil"
        },
        "share": {
          "button": "%{title}, en produktberättelse från %{organizationName}",
          "copied_clipboard": "Kopierade till urklipp",
          "facebook": "Dela på Facebook",
          "intro": "Dela på",
          "pinterest": "Dela på Pinterest",
          "twitter": "Dela på Twitter"
        }
      }
    },
    "js": {
      "checking": "Kontroll",
      "evidenced": "Bevisas",
      "footer_cta": "Följ för tips om att shoppa medvetet!",
      "proof_point": {
        "partly_recyclable_packaging": "Delvis Återvinningsbar Förpackning",
        "vegan": "Vegansk"
      },
      "theme": {
        "nature": "Naturen",
        "waste": "Avfallet"
      },
      "unverified": "Overifierad",
      "verified_label": "Verifierad",
      "view_profile": "Visa profil"
    },
    "products": {
      "js": null
    },
    "proof_points": {
      "js": {
        "certificates": {
          "view_scheme": null
        },
        "claims": {
          "how_achieved": null,
          "label": "Påståenden",
          "title": "%{claimHeaderName} anspråk",
          "what": "Vad dem gör"
        },
        "connection": {
          "network": "Nätverk"
        },
        "evidence": {
          "no_evidence": "Inga bevis har lagts till ännu.",
          "see_proof": null,
          "self_evidenced": "Varumärket har tillhandahållit sina egna bevis för att stödja detta påstående.",
          "verified_by": "Kravet på %{claimName} har verifierats av denna tredje part",
          "view": null,
          "view_verifier": "Visa verifierare"
        },
        "find_more": "Få reda på mer",
        "location": {
          "where": "Var de är"
        },
        "meaning": {
          "what": null
        },
        "network": {
          "provenance": null,
          "verified": null,
          "view_profile": null
        },
        "product": {
          "associated": null
        },
        "proof": {
          "see_record": null,
          "view_claim": null,
          "view_permanent": null
        },
        "report": {
          "unverified_cta": "Vill du se starkare bevis?",
          "verified_cta": "Något som inte är tydligt om denna information?"
        },
        "title": "Bevispoäng",
        "valid_until": "Giltig till:",
        "verified": {
          "3rd_party": null,
          "how": null
        }
      }
    },
    "data_table": {
      "js": {
        "proof_point_level_tooltip": "Proof Points can be attributed to either a singular Product or your Brand as a whole, depending on the evidence submitted"
      }
    }
  }
}
