import c0 from './alert_controller';
import c1 from './alert_trigger_controller';
import c2 from './app_analytics_controller';
import c3 from './billing_controller';
import c4 from './chat_controller';
import c5 from './cookie_banner_controller';
import c6 from './copy_input_value_controller';
import c7 from './custom_evidence_toggle_controller';
import c8 from './custom_radio_controller';
import c9 from './data_table/evidence_data_table_controller';
import c10 from './data_table/product_data_table_controller';
import c11 from './data_table/proof_points_data_table_controller';
import c12 from './dialog_controller';
import c13 from './dropzone_controller';
import c14 from './filter_controller';
import c15 from './filter_option_controller';
import c16 from './hamburger_controller';
import c17 from './ingredient_assistant_controller';
import c18 from './ingredients_toggle_button_controller';
import c19 from './nested_form_controller';
import c20 from './packaging_component_form_controller';
import c21 from './packaging_set_controller';
import c22 from './packaging_set_form_controller';
import c23 from './proof_point_subject_type_form_controller';
import c24 from './resource_list_controller';
import c25 from './tip_controller';
export const definitions = [
	{identifier: 'alert', controllerConstructor: c0},
	{identifier: 'alert-trigger', controllerConstructor: c1},
	{identifier: 'app-analytics', controllerConstructor: c2},
	{identifier: 'billing', controllerConstructor: c3},
	{identifier: 'chat', controllerConstructor: c4},
	{identifier: 'cookie-banner', controllerConstructor: c5},
	{identifier: 'copy-input-value', controllerConstructor: c6},
	{identifier: 'custom-evidence-toggle', controllerConstructor: c7},
	{identifier: 'custom-radio', controllerConstructor: c8},
	{identifier: 'data-table--evidence-data-table', controllerConstructor: c9},
	{identifier: 'data-table--product-data-table', controllerConstructor: c10},
	{identifier: 'data-table--proof-points-data-table', controllerConstructor: c11},
	{identifier: 'dialog', controllerConstructor: c12},
	{identifier: 'dropzone', controllerConstructor: c13},
	{identifier: 'filter', controllerConstructor: c14},
	{identifier: 'filter-option', controllerConstructor: c15},
	{identifier: 'hamburger', controllerConstructor: c16},
	{identifier: 'ingredient-assistant', controllerConstructor: c17},
	{identifier: 'ingredients-toggle-button', controllerConstructor: c18},
	{identifier: 'nested-form', controllerConstructor: c19},
	{identifier: 'packaging-component-form', controllerConstructor: c20},
	{identifier: 'packaging-set', controllerConstructor: c21},
	{identifier: 'packaging-set-form', controllerConstructor: c22},
	{identifier: 'proof-point-subject-type-form', controllerConstructor: c23},
	{identifier: 'resource-list', controllerConstructor: c24},
	{identifier: 'tip', controllerConstructor: c25},
];
